import React, { Component } from 'react';
import reactCSS from 'reactcss'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { 
  IonApp, 
  IonContent, 
  IonGrid, 
  IonRow, 
  IonCol, 
  IonInput,
  IonButton,
  IonText,
  IonSpinner,
  IonMenu,
  IonPage,
  IonItem,
  IonMenuToggle,
  IonIcon,
  IonList,
  IonLabel
} from '@ionic/react';
import { menu, eye, eyeOffOutline, warning } from 'ionicons/icons';
import { Toaster } from 'react-hot-toast';
import 'maplibre-gl/dist/maplibre-gl.css';
import Map, { Marker } from 'react-map-gl/maplibre';
// import { Canvas, Coordinates } from "react-three-map/maplibre";
// import { Canvas } from "@react-three/fiber";
import maplibregl from '!maplibre-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { v4 as uuid } from 'uuid';
import { SketchPicker } from 'react-color';

import { 
    DEFAULT_MAXBOUNDS, 
    DEFAULT_CENTRE,
    CKAN_INDEX_DOWNLOADER
} from '../constants';
import { global } from "../actions";
import { mapRefreshProjectTypes } from "../functions/map";
import FeatureService from 'mapbox-gl-arcgis-featureserver'


import { 
  TILESERVER_BASEURL
} from "../constants";

/**
 * Main template class 
 */
class Main extends Component {

    constructor(props, context) {
      super(props, context);
      this.state = {
        showmarker: true,
        loading: true,
        fillcolor: {r: 200, g: 200, b: 200, a: 0.5},
        linecolor: {r: 100, g: 100, b: 100, a: 1},
        url: '',
        externaldatasetcount: null,
        ckan: 'https://ckan.wewantwind.org',
        ui_layers: []
      };
      this.mapRef = React.createRef();
      this.stylesheet = this.incorporateBaseDomain(TILESERVER_BASEURL, require('../constants/blank.json'));  
    }

    incorporateBaseDomain = (baseurl, json) => {

      let newjson = JSON.parse(JSON.stringify(json));
      const sources_list = ['openmaptiles', 'terrainSource', 'hillshadeSource', 'allplanningconstraints', 'planningconstraints', 'windspeed', 'renewables', 'projects'];
  
      for(let i = 0; i < sources_list.length; i++) {
        var id = sources_list[i];
        if (id in newjson['sources']) {
          if ('url' in newjson['sources'][id]) {
            if (!(newjson['sources'][id]['url'].startsWith('http'))) {
              newjson['sources'][id]['url'] = baseurl + newjson['sources'][id]['url'];
            }       
          }
        }
      }
  
      newjson['glyphs'] = baseurl + newjson['glyphs'];
      newjson['sprite'] = baseurl + newjson['sprite'];

      return newjson;
    }

    componentDidMount(){
      // let params = queryString.parse(this.props.location.search);
      // if ((params.lat !== undefined) && (params.lng !== undefined)) {
      //     var lat = parseFloat(params.lat);
      //     var lng = parseFloat(params.lng);
      //     this.loadingurl = true;

      //     if ((this.props.global.startinglat !== null) && (this.props.global.startinglng !== null)) 
      //     {
      //       this.showTurbine({startingposition: {latitude: this.props.global.startinglat, longitude: this.props.global.startinglng}, turbineposition: {latitude: lat, longitude: lng}});
      //     } else {
      //       if (navigator.geolocation) {
      //             navigator.geolocation.getCurrentPosition((position) => {
      //               this.showTurbine({startingposition: {latitude: position.coords.latitude, longitude: position.coords.longitude}, turbineposition: {latitude: lat, longitude: lng}})
      //             }, this.notfoundCurrentPosition);
      //       } else {
      //         this.loadingurl = false;
      //       }              
      //   }
      // }         
    }
      
    onMapLoad = (event) => {
        
        var map = this.mapRef.current.getMap();

        // var layers = [
        //   {
        //     color: 'red',
        //     opacity: 0.5,
        //     url: 'https://services.arcgis.com/JJzESW51TqeY9uat/arcgis/rest/services/SSSI_England/FeatureServer/0'
        //   },
        //   {
        //     color: 'blue',
        //     opacity: 0.5,
        //     url: 'https://services-eu1.arcgis.com/ZOdPfBS3aqqDYPUQ/arcgis/rest/services/Conservation_Areas/FeatureServer/1'
        //   }  
        // ]

        // for (var i = 0; i < layers.length; i++)
        // {
        //   var source_id = 'source_' + i.toString();
        //   var layer = new FeatureService(source_id, map, {url: layers[i].url});
        //   map.addLayer({
        //     'id': source_id,
        //     'source': source_id,
        //     'type': 'fill',
        //     'paint': {
        //       'fill-opacity': layers[i].opacity,
        //       'fill-color': layers[i].color
        //     }
        //   });    
        // }

        // map.showPadding = true;

        map.addControl(new maplibregl.AttributionControl(), 'bottom-left');

        // let scale = new maplibregl.ScaleControl({
        //   maxWidth: 2000,
        //   unit: 'metric',
        //   style: 'map-scale'
        // });

        map.setSky({
          'sky-color': '#b7d0c8',
          'sky-horizon-blend': 1,
          'horizon-color': '#f8f8ee',
          'horizon-fog-blend': 0.1,
          'fog-color': '#f8f8ee',
          'fog-ground-blend': 0.6 // 0 is maximum blend, 1 is zero blend (non-existent)
        });

        this.props.setGlobalState({
            'mapref': this.mapRef, 
        });
  
        // // disable map rotation using right click + drag
        // map.dragRotate.disable();

        // // disable map rotation using keyboard
        // map.keyboard.disable();

        // // disable map rotation using touch rotation gesture
        // map.touchZoomRotate.disableRotation();

        this.setState({loading: false});
    }
      
    rgbToHex = (color) => {
 
      let red = color.r.toString(16).padStart(2, '0'); // FF
      let green = color.g.toString(16).padStart(2, '0'); // C0
      let blue = color.b.toString(16).padStart(2, '0'); // CB
     
      return '#' + red + green + blue; // #FFC0CB
    }

    toggleLayerVisibility = (layer_id) => {
      var layers = this.state.ui_layers;
      for(var i = 0; i < layers.length; i++) {
        var layer = layers[i];
        if (layer['id'] === layer_id) {
          layers[i]['visible'] = !layers[i]['visible'];
          if (layers[i]['type'] === 'group') {
            for(var j = 0; j < layers.length; j++) {
              if (layers[j]['parentid'] === layer['id']) this.setDataLayerVisibility(layers[j]['id'], layers[i]['visible']);
            }
          } else {
            var map = this.mapRef.current.getMap();
            switch (layers[i]['type']) {
              case "ArcGIS GeoServices REST API":
                map.setLayoutProperty(layer['id'] + '_fill', 'visibility', layers[i]['visible'] ? 'visible': 'none');
                map.setLayoutProperty(layer['id'] + '_line', 'visibility', layers[i]['visible'] ? 'visible': 'none');
                break;
              default:
                map.setLayoutProperty(layer['id'], 'visibility', layers[i]['visible'] ? 'visible': 'none');
                break;
            }  
          }
          this.setState({ui_layers: layers});
          return;
        }
      }
    }

    setDataLayerVisibility = (layer_id, visible) => {
      var layers = this.state.ui_layers;
      for(var i = 0; i < layers.length; i++) {
        var layer = layers[i];
        if ((layer['id'] === layer_id) && (layer['type'] !== 'group')) {
          layers[i]['visible'] = visible;
          var map = this.mapRef.current.getMap();
          switch (layers[i]['type']) {
            case "ArcGIS GeoServices REST API":
              map.setLayoutProperty(layer['id'] + '_fill', 'visibility', layers[i]['visible'] ? 'visible': 'none');
              map.setLayoutProperty(layer['id'] + '_line', 'visibility', layers[i]['visible'] ? 'visible': 'none');
              break;
            default:
              map.setLayoutProperty(layer['id'], 'visibility', layers[i]['visible'] ? 'visible': 'none');
              break;
          }
          this.setState({ui_layers: layers});
          return;
        }
      }
    }

    loadckan = (url) => {

      url = url.replaceAll('https://', '');
      url = url.replaceAll('http://', '');
      url = CKAN_INDEX_DOWNLOADER + '/' + url;

      var map = this.mapRef.current.getMap();
      this.setState({loading: true});

      fetch(url, {
        mode: 'cors',
        method: 'GET',
        headers: {'Content-Type':'application/json', 'Access-Control-Allow-Origin': '*'},
      })
        .then(response => response.json())
        .then(data => {
          this.setState({loading: false});          
          var groups = Object.keys(data);
          var num_externaldatasets = 0;
          var ui_layers = [];
          for (var i = 0; i < groups.length; i++) {
            var layers = data[groups[i]]['datasets'];
            var data_color = data[groups[i]]['color'];
            var fillcolor = this.rgbToHex(this.state.fillcolor), linecolor = this.rgbToHex(this.state.linecolor);
            if (data_color !== '') {
              fillcolor = data_color;
              linecolor = data_color;
            }
            const group_id = 'group_' + uuid();
            var color = this.standardize_color(data[groups[i]]['color']);
            ui_layers.push({id: group_id, parentid: null, level: 0, color: color, type: 'group', title: data[groups[i]]['title'], visible: true})
            for(var j = 0; j < layers.length; j++) {
              const source_id = 'source_' + uuid();
              var viewablelayer = false;

              switch(layers[j]['type']) {
                case 'ArcGIS GeoServices REST API':

                  num_externaldatasets++;
                  viewablelayer = true;

                  new FeatureService(source_id, map, {url: layers[j]['url']});
                  map.addLayer({
                    'id': source_id + "_fill",
                    'source': source_id,
                    'type': 'fill',
                    'paint': {
                      'fill-opacity': this.state.fillcolor.a,
                      'fill-color': fillcolor,
                    }
                  });    
                  map.addLayer({
                    'id': source_id + "_line",
                    'source': source_id,
                    'type': 'line',
                    'paint': {
                      'line-opacity': this.state.linecolor.a,
                      'line-color': linecolor,
                    }
                  });            
  
                  break;
                case 'WMS':

                  // map.addSource(source_id, {
                  //     type: 'raster',
                  //     tiles: [layers[j]['url']],
                  //     tileSize: 256
                  //   });
            
                  // map.addLayer(
                  //   {
                  //     id: source_id,
                  //     type: 'raster',
                  //     source: source_id,
                  //     paint: {}
                  //   },
                  //   ''
                  // );

                  break;
                case 'WMTS':

                  num_externaldatasets++;
                  viewablelayer = true;

                  fetch(layers[j]['url'], {
                    mode: 'cors',
                    method: 'GET',
                    headers: {'Content-Type':'application/json', 'Access-Control-Allow-Origin': '*'},
                  })
                    .then((wmts_response) => {
                      return wmts_response.text();
                    })
                    .then(wmts_data => {
                      var parser = new DOMParser();
                      var xmlDoc = parser.parseFromString(wmts_data, "text/xml");
                      var wmts_link = xmlDoc.getElementsByTagName("ResourceURL")[0].getAttribute('template');
                      wmts_link = wmts_link.replaceAll('TileCol', 'x');
                      wmts_link = wmts_link.replaceAll('TileRow', 'y');
                      wmts_link = wmts_link.replaceAll('TileMatrix', 'z');

                      map.addLayer(
                        {
                          id: source_id,
                          type: "raster",
                          source: {
                            type: "raster",
                            tiles: [wmts_link],
                            tileSize: 256,
                            attribution: ''
                          },
                    
                          paint: {}
                        }
                      );
                  });

                  break;

                default:
                  break;
              }

              if (viewablelayer){
                ui_layers.push({id: source_id, color: color, parentid: group_id, level: 1, type: layers[j]['type'], title: layers[j]['title'], visible: true})
              }
            }
          }

          console.log(ui_layers);
          this.setState({ui_layers: ui_layers, externaldatasetcount: num_externaldatasets})
        });

    }


    loadurl = (url) => {

      url = url.replaceAll('/query?outFields=*&where=1%3D1', '');
      var meaningfultitle = url.replace( new RegExp("/FeatureServer/[0-9/]*",""),"");
      meaningfultitle = meaningfultitle.replace( new RegExp(".*/services/",""),"");
      meaningfultitle = meaningfultitle.replaceAll("_", " ");

      var map = this.mapRef.current.getMap();
      var source_id = 'source_' + uuid();
      new FeatureService(source_id, map, {url: url});

      map.addLayer({
        'id': source_id + "_fill",
        'source': source_id,
        'type': 'fill',
        'paint': {
          'fill-opacity': this.state.fillcolor.a,
          'fill-color': this.rgbToHex(this.state.fillcolor),
        }
      });    
      map.addLayer({
        'id': source_id + "_line",
        'source': source_id,
        'type': 'line',
        'paint': {
          'line-opacity': this.state.linecolor.a,
          'line-color':this.rgbToHex(this.state.linecolor),
        }
      });    

      var ui_layers = this.state.ui_layers;
      ui_layers.push({id: source_id, color: '#999999', parentid: null, level: 1, type: 'ArcGIS GeoServices REST API', title: meaningfultitle, visible: true})
      this.setState({ui_layers: ui_layers});

    }

    capitalizeFirstLetter(string) {
        return (string.charAt(0).toUpperCase() + string.slice(1)).replaceAll(":", " ");
    }
    
    onMouseEnter = (event) => {
        console.log("onMouseEnter");
        // var map = this.mapRef.current.getMap();
  
        // if (event.features.length > 0) {
        //   if (this.hoveredPolygonId === null) {
        //     if (event.features[0].sourceLayer === 'windspeed') {
        //       var windspeed = parseFloat(event.features[0].properties['DN'] / 10);
        //       this.props.setGlobalState({'windspeed': windspeed});
        //       return;
        //     }
                
        //     if ((event.features[0].source === 'planningconstraints') || 
        //         (event.features[0].sourceLayer === 'landuse')) {
        //       // If mouse over planning constraints, disable add wind turbine
        //       if (this.props.global.editcustomgeojson === 'wind') {
        //         this.props.global.mapdraw.changeMode('simple_select');
        //       }
        //       return;
        //     }
  
        //     map.getCanvas().style.cursor = 'pointer';
        //     var properties = event.features[0].properties;
        //     // Note unique numberical ID required for setFeatureState
        //     // OSM ids, eg 'way/12345' don't work
        //     this.hoveredPolygonId = event.features[0].id;  
        //     var featurecentroid = centroid(event.features[0]);
        //     var description = properties.name;
        //     var source = "";
        //     if (properties['power'] !== undefined) {
    
        //       if (properties['plant:source'] !== undefined) source = properties['plant:source'];
        //       if (properties['generator:source'] !== undefined) source = properties['generator:source'];
        //       if (description === undefined) {
        //         if (source === "solar") description = "Solar Farm";
        //         if (source === "wind") description = "Wind Farm";  
        //       }
    
        //       if (['line', 'minor_line'].includes(properties['power'])) {
        //         description = 'Power line';
        //       }
        //       if (properties['power'] === 'substation') {
        //         description = 'Substation';
        //       }
        //       if (properties['power'] === 'cable') {
        //         description = 'Underground cable';
        //       }
    
        //       if (!(['solar', 'wind'].includes(source))) {
        //         if (properties.name !== undefined) description += ' - ' + properties.name;
        //       }
        //       description = description.replaceAll('?', '');
        //       description = '<h1 class="popup-h1">' + description + '</h1>';
        //       var showelements = ['voltage', 'cables', 'circuits', 'operator', 'number:of:elements', 'plant:output:electricity'];
        //       for(var i = 0; i < showelements.length; i++) {
        //         var element = showelements[i];
        //         var value = properties[element];
        //         var firstcap = this.capitalizeFirstLetter(element);
        //         firstcap = firstcap.replace('Number of elements', 'Number of turbines');
        //         firstcap = firstcap.replace('Plant output electricity', 'Power output');
        //         if (value !== undefined) {
        //           if (element === 'voltage') value = value.replaceAll(';', ', ');
        //           description += '<p class="popup-p"><b>' + firstcap + ':</b> ' + value + '</p>';
        //         }
        //       }
    
        //     } else {
        //       if (properties['subtype'] === 'votes') {
        //         description = '<h1 class="popup-h1">Turbine site votes</h1>';
        //         description += '<p class="popup-p">' + properties['position'] + '</p>';
        //         description += '<p class="popup-p"><b>All votes:</b> ' + properties['votes'] + '</p>';
        //         description += '<p class="popup-p"><b>Votes within 1 mile:</b> ' + properties['votes:within:1:mile'] + '</p>';
        //         description += '<p class="popup-p"><b>Votes within 5 miles:</b> ' + properties['votes:within:5:miles'] + '</p>';
        //         description += '<p class="popup-p"><b>Votes within 10 miles:</b> ' + properties['votes:within:10:miles'] + '</p>';
        //         description += '<p class="popup-p"><i>Click to vote for this site</i></p>';
        //       }
        //       if (properties['subtype'] === 'planningapplications') {
        //         description = '<h1 class="popup-h1">Planning&nbsp;application:&nbsp;' + properties['status'] + '</h1>';
        //         description += '<p class="popup-p"><b>Position: </b>' + properties['lat'].toString() + '°N ' + properties['lng'].toString() + '°E</p>';
        //         description += '<p class="popup-p"><b>Identifier: </b>' + properties['id'] + '</p>';
        //         description += '<p class="popup-p"><b>Date: </b>' + properties['date:of:decision'] + '</p>';
        //         description += '<p class="popup-p">' + properties['name'] + '</p>';
        //         description += '<p class="popup-p"><b>Type of application: </b>' + properties['type:of:application'] + '</p>';
        //         description += '<p class="popup-p"><i>Planning application data obtained from planning websites via planit.org.uk. All data copyright of respective planning authorities</i></p>';
        //         description += '<p class="popup-p"><b>Click point to view application</b></p>';
        //       }

        //       if (description === undefined) {
        //         description = "No name available";
        //         source = "";
        //         if (properties['plant:source'] !== undefined) source = properties['plant:source'];
        //         if (properties['generator:source'] !== undefined) source = properties['generator:source'];
        //         if (source === "solar") description = "Solar Farm";
        //         if (source === "wind") description = "Wind Farm";
        //       }
        //     }
        //     var popup = this.popupRef.current;
        //     if (popup !== null) {
        //         popup.setOffset([0, 0]);
        //         if (properties['renewabletype'] !== undefined) popup.setOffset([0, -10]);
        //         if (properties['subtype'] === 'votes') popup.setOffset([0, -30]);
        //         if (properties['subtype'] === 'planningapplications') popup.setOffset([0, -30]);
        //         popup.setLngLat(featurecentroid.geometry.coordinates).setHTML(description).addTo(map);    
        //     }
        //   }  
        // }
    }
    
    onMouseMove = (event) => {
      console.log("onMouseMove");

        // if (this.props.global.page !== PAGE.EXPLORE) return;
    
        // if (event.features.length > 0) {
        //   if (event.features[0].sourceLayer === 'windspeed') {
        //     var windspeed = parseFloat(event.features[0].properties['DN'] / 10);
        //     this.props.setGlobalState({'windspeed': windspeed});
        //     return;
        //   }
    
        // } 
          
        // if (this.hoveredPolygonId) {
        //   var popup = this.popupRef.current;
        //   if (popup !== null) popup.setLngLat(event.lngLat);
        // }
    }
    
    onMouseLeave = (event) => {
      console.log("onMouseLeave");


        // this.props.setGlobalState({'windspeed': null});
        // var map = this.mapRef.current.getMap();
        // var popup = this.popupRef.current;    
        // this.hoveredPolygonId = null;
        // map.getCanvas().style.cursor = '';
        // if (popup !== null) popup.remove();  
    }
      
    callUnity = (repdid, name) => {
      var unitycall = 'loadasset/' + repdid;
      console.log("Unity.call", unitycall, name);
      if (window.hasOwnProperty('Unity')) window.Unity.call(unitycall);
    }

    onClick = (event) => {
      if (event.features.length > 0) {
        var properties = event.features[0].properties;
        this.callUnity(properties['id'], properties['name']);
      }
    }
          
    onClickMarker = () => {
      console.log("onClickMarker");
        // if (this.props.global.page === PAGE.EXPLORE) {
        //     const map = this.mapRef.current.getMap();
        //     var centre = [this.props.global.turbinelng, this.props.global.turbinelat];
        //     this.props.setGlobalState({centre: centre});
        //     this.setState({centreset: true});
        //     map.easeTo({center: centre, pitch: 85, zoom: 15.5, duration: 1000});
        //     return false;    
        // } else {
        //     return false;
        // }
    }
  
    onTurbineMarkerDragEnd = (event) => {
      console.log("onTurbineMarkerDragEnd");
        // const lnglat = event.target.getLngLat();
        // const map = event.target._map;
        // const point = map.project(lnglat);
        // const features = map.queryRenderedFeatures(point);

        // this.props.setGlobalState({turbinelat: lnglat.lat, turbinelng: lnglat.lng}).then(() => {
        //   var map = this.mapRef.current.getMap();
        //   if ((this.props.global.page === PAGE.NEARESTTURBINE) || (this.props.global.page === PAGE.SHOWTURBINE)) {
        //       this.reorientToTurbine(map);    
        //   }
        //   this.refreshVisibility();
        // });          
    }
  
    onEyeMarkerDragEnd = (event) => {
      console.log("onEyeMarkerDragEnd");

      // if (this.state.flying) return;

      // const lnglat = event.target.getLngLat();
      // this.props.setGlobalState({currentlat: lnglat.lat, currentlng: lnglat.lng}).then(() => {
      //   var map = this.mapRef.current.getMap();
      //   this.reorientToTurbine(map);
      // });     
    }
    
    // onIdle = () => {
    //   console.log("onIdle");
    // }
  
    onMapMoveEnd = (event) => {

      console.log("onMapMoveEnd");
      // var map = this.mapRef.current.getMap();
    }  
 
    toggleProjectType = (projecttype) => {
      var projecttypes = this.props.global.projecttypes;
      projecttypes[projecttype] = !(projecttypes[projecttype]);
      this.props.setGlobalState({projecttypes: projecttypes}).then(() => {
        mapRefreshProjectTypes(
          this.props.global.projecttypes,
          this.mapRef.current.getMap());
      });
    }


    standardize_color = (str) => {
      var ctx = document.createElement("canvas").getContext("2d");
      ctx.fillStyle = str;
      return ctx.fillStyle;
    }

    render() {

        const styles = reactCSS({
          'default': {
            fillcolor: {
              width: '36px',
              height: '14px',
              borderRadius: '2px',
              background: `rgba(${ this.state.fillcolor.r }, ${ this.state.fillcolor.g }, ${ this.state.fillcolor.b }, ${ this.state.fillcolor.a })`,
            },
            linecolor: {
              width: '36px',
              height: '14px',
              borderRadius: '2px',
              background: `rgba(${ this.state.linecolor.r }, ${ this.state.linecolor.g }, ${ this.state.linecolor.b }, ${ this.state.linecolor.a })`,
            },
            swatch: {
              background: '#fff',
              borderRadius: '1px',
              boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
              display: 'inline-block',
              cursor: 'pointer',
            },
            popover: {
              position: 'absolute',
              zIndex: '2',
            },
            cover: {
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            },
          },
        });

        return (
        <>
        <IonApp>


          <IonMenu type="push" contentId="main-content">
          <IonContent className="ion-padding">


          <IonList style={{padding: "0px"}}>
          {(this.state.ui_layers.length === 0) ? (
            <IonItem lines="none">
              <IonIcon color="warning" aria-hidden="true" size="medium" icon={warning} slot="start"></IonIcon>
              <IonLabel>No data layers loaded</IonLabel>
            </IonItem>
          ): null}
          {this.state.ui_layers.map((ui_layer, index) => {
            const left_padding = (ui_layer['level'] * 0).toString() + 'px';
            var font_size = 'auto';
            var font_weight = '500';
            var color = ui_layer['color'] + 'FF';
            var text_color = '#FFFFFF';
            var text_shadow = '1px 1px 10px #0e0e0e96';
            if (ui_layer['level'] > 0) {
              font_size = '90%';
              font_weight = '300';
              color = ui_layer['color'] + '11';
              text_color = '#000000';
              text_shadow = 'none';
            }
            return (
              <IonItem key={index} lines="full" color="clear" style={{padding: "0px !important", backgroundColor: color}}>
                <IonIcon style={{color: text_color}} onClick={() => this.toggleLayerVisibility(ui_layer.id)} aria-hidden="true" size="small" icon={ui_layer['visible'] ? eye : eyeOffOutline} slot="end"></IonIcon>
                <IonLabel style={{fontSize: font_size, fontWeight: font_weight, color: text_color, textShadow: text_shadow, marginLeft: left_padding}}>{ui_layer.title}</IonLabel>
              </IonItem>
            )
          })}
          </IonList>



          </IonContent>
          </IonMenu>
          <IonPage id="main-content">
            <IonContent className="ion-padding" fullscreen="true">

              <div className="map-wrap">
                  <Toaster position="top-center" containerStyle={{top: 50}}/>
                  <div className="map-wrap">
                    <div style={{height: "100%"}}>

                      <Map ref={this.mapRef}
                        width="100vw"
                        height="100vh"
                        onLoad={this.onMapLoad} 
                        onClick={this.onClick}    
                        onDrag={this.onDrag}
                        interactiveLayerIds={[ 
                          'projects_title',
                          'projects_circle',
                          'projects_circle_shadow',
                          'projects_circle_clickable'
                        ]}
                        mapStyle={this.stylesheet}
                        terrain={{source: "terrainSource", exaggeration: 1.1 }}
                        attributionControl={false}
                        minZoom={4}
                        maxZoom={15}
                        maxBounds={DEFAULT_MAXBOUNDS}
                        initialViewState={{
                          bounds: DEFAULT_MAXBOUNDS,
                          longitude: DEFAULT_CENTRE[0],
                          latitude: DEFAULT_CENTRE[1],
                          minPitch: 0,
                          maxPitch: 85,
                          fitBoundsOptions: { padding: 100}
                        }} >

                          <Marker
                              style={{display: 'block'}} 
                              longitude={this.props.global.currentlng} 
                              latitude={this.props.global.currentlat} anchor="center" >
                              <img alt="Your location" width="40" src="./static/icons/eye.png" />
                          </Marker>                  
                      </Map>

                    </div>
                  </div>
                </div>      

                <div style={{position: "absolute", top: "0px", left: "0px", width:"100%", zIndex: "9999"}}>
                  <div style={{padding: "7px", margin: "15px", marginLeft: "55px", backgroundColor: 'rgba(255,255,255,1)', borderRadius: "15px" }}>
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                        <h1 style={{margin: "0px", padding: "0px", marginBottom: "5px"}}>External dataset viewer</h1>
                        </IonCol>
                      </IonRow>

                      <IonRow className="ion-align-items-center ion-justify-content-center">
                        <IonCol size="12">
                          <IonInput label="ArcGIS Feature URL" labelPlacement="stacked" fill="outline" value={this.state.url} onIonInput={(event) => this.setState({url: event.target.value})}>
                            <IonButton clear slot="end" onClick={() => this.loadurl(this.state.url)}>Load</IonButton>
                            <IonButton clear slot="end" onClick={() => window.open('https://ckan.wewantwind.org/dataset/?res_format=ArcGIS+GeoServices+REST+API', '_new')}>Browse CKAN</IonButton>
                          </IonInput>
                        </IonCol>
                        <IonCol>
                        </IonCol>
                      </IonRow>

                      <IonRow className="ion-align-items-center ion-justify-content-center">
                        <IonCol size="12">
                          <IonInput label="CKAN Open Data Portal" labelPlacement="stacked" fill="outline" value={this.state.ckan} onIonInput={(event) => this.setState({ckan: event.target.value})}>
                            <IonButton clear slot="end" onClick={() => this.loadckan(this.state.ckan)}>Load</IonButton>
                          </IonInput>
                        </IonCol>
                        <IonCol>
                        </IonCol>
                      </IonRow>

                      <IonRow className="ion-align-items-center ion-justify-content-center">
                        <IonCol size="6">
                          <IonText style={{paddingRight: "10px"}}>Fill</IonText> 
                          <div style={ styles.swatch } onClick={() => {this.setState({ displayColorPicker_Fill: !this.state.displayColorPicker_Fill })}}>
                            <div style={ styles.fillcolor } />
                          </div>
                          { this.state.displayColorPicker_Fill ? <div style={ styles.popover }>
                            <div style={ styles.cover } onClick={() => this.setState({ displayColorPicker_Fill: false })}/>
                            <SketchPicker color={ this.state.fillcolor } onChange={(fillcolor) => this.setState({ fillcolor: fillcolor.rgb })} />
                          </div> : null }
                          <IonText style={{paddingLeft: "20px", paddingRight: "10px"}}>Line</IonText> 
                          <div style={ styles.swatch } onClick={() => {this.setState({ displayColorPicker_Line: !this.state.displayColorPicker_Line })}}>
                            <div style={ styles.linecolor } />
                          </div>
                          { this.state.displayColorPicker_Line ? <div style={ styles.popover }>
                            <div style={ styles.cover } onClick={() => this.setState({ displayColorPicker_Line: false })}/>
                            <SketchPicker color={ this.state.linecolor } onChange={(linecolor) => this.setState({ linecolor: linecolor.rgb })} />
                          </div> : null }
                        </IonCol>
                        <IonCol size="6" style={{textAlign: "right"}}>
                          { this.state.externaldatasetcount ? (
                            <IonText>
                              Number of external datasets loaded: <b>{this.state.externaldatasetcount}</b>
                            </IonText>
                          ): null}
                        </IonCol>
                      </IonRow>

                    </IonGrid>
                  </div>

                  <IonMenuToggle>
                    <IonButton style={{position: "absolute", top: "5px", zIndex: 1000}} color="light" size="large" fill="clear">
                      <IonIcon slot="start" icon={menu}></IonIcon>
                    </IonButton>
                  </IonMenuToggle>

                </div>

                {this.state.loading ? (<IonSpinner color="tertiary"/>) : null}


            </IonContent>
          </IonPage>

        </IonApp>

        </>);
    }
}

export const mapStateToProps = state => {
  return {
    global: state.global
  }
}
    
export const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setGlobalState: (globalstate) => {
            return dispatch(global.setGlobalState(globalstate, ownProps.history, ownProps.location));
        },  
    }
}  

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));