import { 
  PROJECT_TYPES,
} from "../constants";

export function mapRefreshProjectTypes(projecttypes, map) {
    var projecttypes_sections = Object.keys(PROJECT_TYPES);
    var filterList = ['in', 'status'];
    for(let i = 0; i < projecttypes_sections.length; i++) {
      var projecttype_section = projecttypes_sections[i];
      var section_status = false;
      if (projecttype_section in projecttypes) section_status = projecttypes[projecttype_section];
      for(let j = 0; j < PROJECT_TYPES[projecttype_section]['layers'].length; j++) {
        var id = PROJECT_TYPES[projecttype_section]['layers'][j];
        if (section_status) filterList.push(id);
      }
    }

    map.setFilter('projects_circle_shadow', filterList);
    map.setFilter('projects_circle', filterList);
    map.setFilter('projects_title', filterList);
}

